@font-face {
  font-family: "akrobatblack";
  src: url("../fonts/akrobat-black-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatbold";
  src: url("../fonts/akrobat-bold-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatextrabold";
  src: url("../fonts/akrobat-extrabold-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatextralight";
  src: url("../fonts/akrobat-extralight-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatlight";
  src: url("../fonts/akrobat-light-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatregular";
  src: url("../fonts/akrobat-regular-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatsemibold";
  src: url("../fonts/akrobat-semibold-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "akrobatthin";
  src: url("../fonts/akrobat-thin-webfont.woff2") format("woff2"),
    url("../fonts/akrobat-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'good_timesregular';
  src: url('../fonts/good_times_rg-webfont.woff2') format('woff2'),
  url('../fonts/good_times_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
