.popup {
  display: block;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;


  @media #{$tablet_land__1024} {
    top: 80%;
    transform: translate(-50%, -80%);
  }

  @media #{$desktop__1280} {
    top: 60%;
    transform: translate(-50%, -60%);
  }

  @media #{$large__screens__1440} {
    top: 80%;
    transform: translate(-50%, -80%);
  }

  &Close {
    display: none;
  }

  &__wrapper {
    width: calc(100% - 40px);
    max-width: 531px;
    display: flex;
    flex-direction: column;
    border: 2px solid #0c426d;
    border-radius: 10px;
    padding: rem(20px);
    background-color: $white;
    position: relative;
    margin: 0 auto;

    @media #{$mobile_land__640} {
      padding: 25px;
      width: calc(100% - 160px);
    }

    @media #{$tablet_land__1024} {
      width: calc(100% - 40px);
    }

    @media #{$desktop__1280} {
      padding: 50px;
    }
  }

  &__content {
    overflow-y: scroll;
    max-height: 60vh;
    -webkit-overflow-scrolling: touch;
    scrollbar-visibility: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;


    @media #{$mobile_land__640} {
      max-height: 70vh;
    }

    @media #{$tablet__768} {
      max-height: 80vh;
    }

    @media #{$large__screens__1440} {
      max-height: 65vh;
    }

    @media #{$full__screens__1600} {
      max-height: 80vh;
    }

    @media #{$HD__screens__1920} {
      //overflow-y: auto;
      max-height: initial;
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}


.popup__content-logo {
  width: 40%;
  display: block;
  margin: 0 auto;

  @media #{$mobile_land__640} {
    width: 25%;
  }
}

.popup__content-text {
  padding: 49px 0;
  text-align: center;
  white-space: pre-line;
  color: #0c426d;
  font-size: 19px;

  @media #{$mobile_land__640} {
    font-size: 20px;
  }
}

.popup__content-text--bold {
  display: inline-block;
  font-size: 25px;
  padding: 17px 0 13px;
  white-space: pre-line;


  @media #{$mobile_land__640} {
    font-size: 30px;
    white-space: normal;
  }

  @media #{$tablet_land__1024} {
    font-size: 34px;
  }
}

.popup__content-map {
  width: 100%;

  @media #{$mobile_land__640} {
    width: 90%;
    margin: 0 auto;
  }

  @media #{$tablet_land__1024} {
    width: 80%;
  }

  @media #{$desktop__1280} {
    width: 100%;
  }

}

.popup__close {
  cursor: pointer;
}

.popup__close::after {
  content: url("https://www.3ns.com.pl/images/criss-cross-2.svg");
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  z-index: 2;
}

@media #{$mobile_land__640} {
}
@media #{$tablet__768} {
}
@media #{$tablet_land__1024} {
}
@media #{$desktop__1280} {
}
@media #{$large__screens__1440} {
}
@media #{$full__screens__1600} {
}
@media #{$HD__screens__1920} {
}