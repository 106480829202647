.tenders {
  display: flex;
  flex-direction: column;

  &__content {
    padding: rem(20px);

    @media #{$mobile_land__640} {
      padding: rem(40px);
    }

    @media #{$tablet__768} {
      padding: rem(40px 60px);
    }

    @media #{$tablet_land__1024} {
      padding: rem(60px);
    }

    @media #{$desktop__1280} {
      padding: rem(40px);
    }

    @media #{$large__screens__1440} {
      padding: rem(60px);
    }

    @media #{$full__screens__1600} {
      padding: rem(80px);
    }

    @media #{$HD__screens__1920} {
      padding: rem(80px 278px 80px 140px);
    }
  }

  &__title {
    font-family: $font-title;
    font-size: rem(30px);
    text-align: center;
    padding: rem(34px 0);

    @media #{$mobile_land__640} {
      font-size: rem(40px);
      padding: rem(0 0 34px);
    }

    @media #{$tablet_land__1024} {
      text-align: left;
    }

    @media #{$HD__screens__1920} {
      font-size: rem(54px);
    }
  }

  &__item {
    font-size: rem(18px);
    font-family: $font-text;
    padding-bottom: rem(20px);

    @media #{$mobile_land__640} {
      font-size: rem(20px);
    }

    @media #{$tablet_land__1024} {
      font-size: rem(25px);
      text-align: left;
    }

    @media #{$desktop__1280} {
      font-size: rem(30px);

      //&:nth-of-type(2), &:nth-of-type(4) {
      //  font-size: rem(27px);
      //}
    }
  }

  &__link {
    color: $fun-blue;
    display: inline-flex;

    &--1 {
      &::before {
        content: "1. ";
        display: inline-flex;
        vertical-align: top;
        padding: rem(0 20px 0 0);
      }
    }

    &--2 {
      &::before {
        content: "1. ";
        color: transparent;
        display: inline-flex;
        vertical-align: top;
        padding: rem(0 20px 0 0);
      }
    }

    &--3 {

      &::before {
        content: "2. ";
        display: inline-flex;
        vertical-align: top;
        padding: rem(0 20px 0 0);
      }
    }

    &--4 {
      color: $black;

      &::before {
        content: "2. ";
        color: transparent;
        display: inline-flex;
        vertical-align: top;
        padding: rem(0 20px 0 0);
      }
    }
  }

  &__subpage {

    &-anchor {
      display: block;
      font-size: 30px;
      color: $fun-blue;

      @media #{$tablet_land__1024} {
        width: 46%;
      }

      @media #{$desktop__1280} {
        width: 35%;
      }

      @media #{$large__screens__1440} {
        width: 31%;
      }

      @media #{$full__screens__1600} {
        width: 29%;
      }

      @media #{$HD__screens__1920} {
        width: 28%;
      }

      &::before {
        content: 'Pobierz ulotkę';
        font-size: rem(18px);
        display: block;
        padding: rem(30px 0 20px);
        color: $fun-blue;
        text-decoration: underline;

        @media #{$mobile_land__640} {
          font-size: rem(20px);
        }

        @media #{$tablet_land__1024} {
          font-size: rem(25px);
        }

        @media #{$desktop__1280} {
          font-size: rem(30px);
        }
      }
    }

    &-thumbnail {
      width: calc(100% - 80px);
      margin: 0 auto;
      border: 1px solid $black;

      @media #{$mobile_land__640} {
        width: calc(100% - 160px);
      }

      @media #{$tablet__768} {
        width: calc(100% - 180px);
      }

      @media #{$tablet_land__1024} {
        width: 100%;
        margin: 0;
      }
    }

    &-text {
      font-size: rem(18px);
      font-family: $font-text;
      white-space: pre-line;

      @media #{$mobile_land__640} {
        font-size: rem(20px);
      }

      @media #{$tablet_land__1024} {
        font-size: rem(25px);
        text-align: left;
      }

      @media #{$desktop__1280} {
        font-size: rem(30px);
      }

      &--bold {
        font-weight: bold;
      }

      &--underline {
        text-decoration: underline;
      }
    }

    &-heading {
      font-family: $font-title;
      font-size: rem(30px);
      text-align: center;
      padding: rem(34px 0);
      margin-top: rem(40px);

      @media #{$mobile_land__640} {
        font-size: rem(40px);
        padding: rem(0 0 34px);
      }

      @media #{$tablet_land__1024} {
        text-align: left;
      }
    }

    &-list {

      &--2 {
        padding-bottom: rem(20px);
        margin-left: rem(16px);
      }
    }

    &-item {
      font-size: rem(18px);
      font-family: $font-text;
      list-style-position: inside;
      padding-bottom: rem(20px);

      &:first-child {
        padding-top: rem(20px);
      }

      &:last-child {
        padding-bottom: 0;
      }

      @media #{$mobile_land__640} {
        font-size: rem(20px);
      }

      @media #{$tablet_land__1024} {
        font-size: rem(25px);
        text-align: left;
      }

      @media #{$desktop__1280} {
        font-size: rem(30px);
      }
    }

    &-link {
      color: $fun-blue;
    }
  }

  &-en {
    padding-top: rem(60px);

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: rem(30px);

      @media #{$mobile_land__640} {
        flex-direction: row;
        flex-wrap: wrap;
        //justify-content: space-around;
        justify-content: center;
        margin-left: rem(70px);
      }

      @media #{$tablet_land__1024} {
        flex-wrap: nowrap;
      }

      @media #{$HD__screens__1920} {
       justify-content: center;
      }
    }

    &__img {
      width: 60%;
      padding-bottom: rem(30px);

      &--1 {
        width: 45%;
      }

      &--3 {
        width: 25%;
      }

      &:last-child {
        padding-bottom: 0;
      }

      @media #{$mobile_land__640} {
        width: 30%;
        margin-right: rem(70px);

        &--1,  {
          width: 25%;
        }

        &--3 {
          width: 15%;
          margin-right: rem(120px);
          padding-bottom: 0;
        }
      }

      @media #{$tablet__768} {
        &--3 {
          margin-right: rem(133px);
        }
      }

      @media #{$tablet_land__1024} {
        padding-bottom: 0;

        &--1 {
          width: 22%;
        }

        &--3 {
          width: 15%;
          margin-right: rem(70px);
        }
      }

      @media #{$desktop__1280} {
        &--1 {
          width: 21%;
        }

        &--3 {
          width: 14%;
        }
      }

      @media #{$HD__screens__1920} {
        width: 20%;
        margin-right: rem(100px);

        &:last-child {
          margin-right: 0;
        }

        &--1 {
          width: 14%;
        }

        &--3 {
          width: 8%;
        }
      }
    }
  }
}

@media #{$mobile_land__640} {
}
@media #{$tablet__768} {
}
@media #{$tablet_land__1024} {
}
@media #{$desktop__1280} {
}
@media #{$large__screens__1440} {
}
@media #{$full__screens__1600} {
}
@media #{$HD__screens__1920} {
}