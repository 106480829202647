// ----------------------------------------------------
// ----- MIXINS -----
// ----------------------------------------------------

// --- Clearfix ---
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// ----- End Of Clearfix -----

// ----- END OF MIXINS -----
