.ozonation {
  display: flex;
  flex-direction: column;

  @media #{$tablet_land__1024} {
    padding: rem(0 0 60px);
  }

  @media #{$large__screens__1440} {
    padding: rem(0 0 80px);
  }

  @media #{$HD__screens__1920} {
    padding: rem(0 0 126px);
  }

  &-img {
    @media #{$HD__screens__1920} {
      //width: inherit;
      max-width: none;
      width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(0 20px);

    @media #{$mobile_land__640} {
      padding: rem(0 40px);
    }

    @media #{$tablet_land__1024} {
      padding: rem(0 60px);
      flex-direction: row;
      justify-content: space-between;
    }

    @media #{$desktop__1280} {
      padding: rem(0 40px);
    }

    @media #{$large__screens__1440} {
      padding: rem(0 60px);
    }

    @media #{$full__screens__1600} {
      padding: rem(0 80px);
    }

    @media #{$HD__screens__1920} {
      padding: rem(0 278px);
    }

    &--1 {
      // margin-top: -72px;
      // margin-top: -22.5%;
      margin-top: -22.500vw;

      @media #{$mobile_land__640} {
        // margin-top: -146px;
        // margin-top: -22.8125%;
        margin-top: -22.813vw;
      }

      @media #{$tablet__768} {
        // margin-top: -177px;
        // margin-top: -23.046875%;
        margin-top: -23.047vw;
      }

      @media #{$tablet_land__1024} {
        // margin-top: -150px;
        // margin-top: -14.6484375%;
        margin-top: -14.648vw;
      }

      @media #{$desktop__1280} {
        // margin-top: -216px;
        // margin-top: -16.875%;
        margin-top: -16.875vw;
      }

      @media #{$large__screens__1440} {
        // margin-top: -226px;
        // margin-top: -15.694444444444444%;
        margin-top: -15.694vw;
      }

      @media #{$full__screens__1600} {
        // margin-top: -231px;
        // margin-top: -14.4375%;
        margin-top: -14.438vw;
      }

      @media #{$HD__screens__1920} {
        // margin-top: -270px;
        // margin-top: -14.0625%;
        margin-top: -14.063vw;
      }

      @media (min-width: 1921px) and (max-width: 2560px) {
        // margin-top: -347px;
        // margin-top: -13.5546875%;
        margin-top: -13.555vw;
      }
    }

    &--2 {
      flex-direction: column-reverse;

      @media #{$tablet_land__1024} {
        flex-direction: row;
        padding-top: rem(60px);
      }
    }
  }

  &__col {
    width: 100%;

    &--2, &--6 {

      @media #{$tablet_land__1024} {
        padding-left: rem(60px);
      }

      @media #{$large__screens__1440} {
        padding-left: 0;
      }
    }

    &--3 {

      @media #{$tablet_land__1024} {
        padding-right: rem(60px);
      }

      @media #{$large__screens__1440} {
        padding-right: 0;
      }
    }

    &--1, &--4, &--5 {

      @media #{$tablet_land__1024} {
        width: 40%;
      }
    }

    &--2, &--3, &--6 {

      @media #{$tablet_land__1024} {
        width: 60%;
      }
    }

    @media #{$desktop__1280} {
      width: calc(100% / 2);
    }

    &-title {
      font-family: $font-title;
      font-size: rem(30px);
      text-align: center;
      padding: rem(34px 0);

      @media #{$mobile_land__640} {
        font-size: rem(40px);
        padding: rem(0 0 34px);
      }

      @media #{$tablet_land__1024} {
        text-align: left;
      }

      @media #{$large__screens__1440} {
        margin-top: 0;
      }
    }

    &-text {
      font-family: $font-text;
      font-size: rem(18px);
      text-align: center;
      white-space: pre-line;
      padding-bottom: rem(30px);

      @media #{$mobile_land__640} {
        font-size: rem(20px);
      }

      @media #{$tablet_land__1024} {
        text-align: left;
        padding-bottom: 0;
      }

      @media #{$desktop__1280} {
        font-size: rem(25px);
      }

      @media #{$HD__screens__1920} {
        font-size: rem(30px);
      }
    }
  }


  &__graphics {
    width: 50%;
    margin: rem(0 auto 30px);
    position: relative;

    @media #{$tablet_land__1024} {
      width: 100%;
      margin: 0;
    }
  }

  &__shadow {
    &--1 {
      width: 100%;
      position: relative;

      &::before {
        content: url("../images/shape02.svg");
        width: 100%;
        position: absolute;
        top:0;
        z-index: -1;
      }
    }

    &--2, &--4 {
      filter: drop-shadow(-50px 50px 70px rgba(25, 95, 169, 0.2));
    }

    &--3 {
      filter: drop-shadow(50px 50px 70px rgba(25, 95, 169, 0.2));
    }
  }

  &__shape {

    &--1 {
      &::before {
        content: url("../images/shape--10.svg");
        position: absolute;
        display: block;
        width: 134px;
        left: -64px;
        bottom: -115px;
        z-index: -1;
        transform: scale(0.3);

        @media #{$mobile_land__640} {
          transform: scale(0.4);
        }

        @media #{$tablet__768} {
          transform: scale(0.5);
        }

        @media #{$desktop__1280} {
          transform: scale(0.6);
        }

        @media #{$large__screens__1440} {
          transform: scale(0.7);
        }

        @media #{$full__screens__1600} {
          transform: scale(0.8);
        }

        @media #{$HD__screens__1920} {
          transform: scale(1);
          left: -56px;
          bottom: -160px;
        }
      }
    }

    &--2 {
      &::before {
        content: url("../images/shape--04.svg");
        position: absolute;
        display: block;
        width: 222px;
        right: -115px;
        top: -89px;
        z-index: -1;
        transform: scale(0.3);

        @media #{$mobile_land__640} {
          transform: scale(0.4);
        }

        @media #{$tablet__768} {
          transform: scale(0.5);
        }

        @media #{$desktop__1280} {
          transform: scale(0.6);
          right: -100px;
        }

        @media #{$large__screens__1440} {
          transform: scale(0.7);
        }

        @media #{$full__screens__1600} {
          transform: scale(0.8);
        }

        @media #{$HD__screens__1920} {
          transform: scale(1);
          right: -109px;
          top: -59px;
        }
      }
    }

    &--3 {
      &::before {
        content: url("../images/shape--09.svg");
        position: absolute;
        display: block;
        width: 118px;
        left: -56px;
        bottom: -101px;
        z-index: -1;
        transform: scale(0.3);

        @media #{$mobile_land__640} {
          transform: scale(0.4);
        }

        @media #{$tablet__768} {
          transform: scale(0.5);
        }

        @media #{$desktop__1280} {
          transform: scale(0.6);
        }

        @media #{$large__screens__1440} {
          transform: scale(0.7);
        }

        @media #{$full__screens__1600} {
          transform: scale(0.8);
        }

        @media #{$HD__screens__1920} {
          transform: scale(1);
        }
      }
    }
  }

  &__img {
    width: inherit;

    filter: drop-shadow(1px 1px 0 $white)
    drop-shadow(-1px 1px 0 $white)
    drop-shadow(1px -1px 0 $white)
    drop-shadow(-1px -1px 0 $white);

    @media #{$tablet_land__1024} {
      width: initial;
      max-width: 100%;
    }

    &--2 {
      margin: 0 0 0 auto;
    }
  }
}

@media #{$mobile_land__640} {
}
@media #{$tablet__768} {
}
@media #{$tablet_land__1024} {
}
@media #{$desktop__1280} {
}
@media #{$large__screens__1440} {
}
@media #{$full__screens__1600} {
}
@media #{$HD__screens__1920} {
}